import {useGlobalState} from 'hooks/useGlobalState';
import {useMessage} from 'hooks/useMessage';
import {Exceptions} from 'messages/Exceptions';
import {useCallback, useRef, useState} from 'react';
import {AWSAppSyncProvider} from 'helper/bb-graphql-provider';
import {getPresignedUploadUrl} from 'graphql/beyondBuddy/FileInformation/queries';
import {Warnings} from 'messages/Warnings';
import {CancelException} from 'hooks/useCancellablePromise';
import {FILEEXTENSIONS_IMAGE} from 'beyond-validators/constants';
import _ from 'lodash';

const mockables = {
    AWSAppSyncProvider,
    fetch: fetch.bind(window),
};
/**
 * Check if an filename ends with one of the provided extensions
 * @param {import('beyond-validators').FileExtension[]} extensions - accepted file extensions
 * @param {string} fileName - the name of the file to be checked
 * @returns {boolean} - indicates wether the file ends with one of the provided extensions
 */
const checkFileExtension = (extensions, fileName) => extensions.some((extension) => _.toLower(fileName)?.endsWith(extension));

/**
 * Helper to upload files to s3 using the presigned url logic
 * @returns {import('./helper').TS3UploadProvider} - an upload function for s3 and the indicator if something will be uploaded
 */
function S3UploadProvider() {
    const {enqueueMessage} = useMessage();

    const {getItem} = mockables.AWSAppSyncProvider();
    const {getGlobal} = useGlobalState();
    const [isUploading, setIsUploading] = useState(false);
    const countUploads = useRef(0);

    // eslint-disable-next-line function-paren-newline
    const upload = useCallback(
        /** @type {import('./helper').S3UploadFunction} */
        async (file, options = {formats: FILEEXTENSIONS_IMAGE}) => {
            const {formats, presignedUrls} = options;
            if (!checkFileExtension(formats, file?.name)) {
                enqueueMessage('S3UploadProvider', Warnings.FILEUPLOAD_UNALLOWED_EXTENSION);
                return {};
            }

            try {
                countUploads.current += 1;
                setIsUploading(true);
                /** @type {import('beyond-types').PresignedFileUrls} */
                const presignedFileInformation = presignedUrls ?? await getItem(getPresignedUploadUrl, {tenantId: getGlobal('tenantId'), filename: file.name});

                // upload the new image
                await mockables.fetch(presignedFileInformation.uploadUrl, {
                    method: 'PUT',
                    body: file,
                });
                return {...presignedFileInformation, file};
            } catch (error) {
                if (!(error instanceof CancelException)) {
                    enqueueMessage('S3UploadProvider', Exceptions.STORAGE_SAVE_ERROR, {error});
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
                return {};
            } finally {
                countUploads.current -= 1;
                setIsUploading(!!countUploads.current);
            }
        }, [countUploads, enqueueMessage, getItem, getGlobal]);
    return {upload, isUploading};
}

export {S3UploadProvider, mockables as s3ProviderMockables};
