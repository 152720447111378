import {
    createContext,
    useCallback, useContext, useEffect, useMemo,
    useRef,
    useState,
} from 'react';
import _ from 'lodash';
import {ItemDataContext} from 'components/Form/ItemData';
import {useMessage} from 'hooks/useMessage';
import {Exceptions} from 'messages/Exceptions';
import {RecommendationManager} from 'helper/recommendation-manager';
import {useVariables} from 'hooks/useVariables';
import {AWSAppSyncProvider} from 'helper/bb-graphql-provider';
import {useGlobalState} from 'hooks/useGlobalState';
import {v4 as uuidv4} from 'uuid';

const {Validator, convertValidationSchema, isDraft} = require('beyond-validators/Validator');

const preventDefault = (evt) => evt.preventDefault();
const mockables = {
    AWSAppSyncProvider,
};

/** @type {import('react').Context<import('./form').TFormContext>} */
const FormContext = createContext(Object());

/**
 * Determines which form fields have changed based on user interaction.
 * @param {object} formData - The form's data structure, where each field may contain metadata such as interaction state.
 * @param {object} data - The current data of the form, typically sourced from an item wrapper.
 * @param {object} initialValues - The initial/default values set in the form.
 * @param {(currentValue: any, initialValue: any) => boolean} hasDataChangedWrapper - A function that checks whether a field's value has changed.
 * @returns {Array<string> | false} - An array of field names that have changed, or `false` if no changes are detected.
 */
const createArrayFromCondition = (formData, data, initialValues, hasDataChangedWrapper) => {
    const result = _.reduce(formData, (acc, {interacted}, k) => {
        if (interacted && hasDataChangedWrapper(_.get(data ?? {}, k), _.get(initialValues ?? {}, k))) {
            acc.push(k);
        }
        return acc;
    }, []);

    return result.length > 0 ? result : false;
};

/**
 *
 *The FormWrapper provides a context to the form elements and handles the data flow between the form elements and the form context.
 * @param {import('./FormElements/formElements').FormWrapperProps} props properties passed to the component.
 * @returns {import('react').ReactElement} The FormContext provider.
 */
function FormWrapper({
    children,
    onSaveCallback,
    onChangeCallback,
    hasDataChanged,
    validatorSchema,
    recommendationConfig,
    messageKey,
    isNewItem,
    noChangeTrack,
    readonly: readonlyRoot,
    context,
}) {
    // the unique id of the form wrapper to be used in the global state
    const formWrapperId = useMemo(() => `${uuidv4()}#${context}`, [context]);
    // loading the essential variables and functions from the corresponding context (View, Edit, List)
    const {
        initialData, initialValues, data, updateValue, load, sync, save, isLoading, isSaving,
    } = useContext(ItemDataContext);

    const formValidationSchemaType = validatorSchema?.type;
    const formValidationSchema = useMemo(() => convertValidationSchema(validatorSchema?.schema), [validatorSchema?.schema]);

    const {
        frontend: formValidationSchemaFrontend,
        attributes: formValidationSchemaAttributes,
    } = formValidationSchema ?? {};

    const disableReloadOnce = useRef(false);

    const [formData, setFormData] = useState(Object());

    const [currentContext, setCurrentContext] = useState(undefined); // important to get first loading
    // console.log(formData);

    const {setGlobal, getGlobal, deleteGlobal} = useGlobalState();

    const hasDataChangedWrapper = useMemo(() => hasDataChanged ?? ((a, b) => !_.isEqual(a, b)), [hasDataChanged]);
    const formHasUnsavedChanges = useMemo(
        () => createArrayFromCondition(formData, data, initialValues, hasDataChangedWrapper),
        // () => _.map(formData, ({interacted}, k) => interacted && !hasDataChangedWrapper(_.get(data ?? {}, k), _.get(initialValues ?? {}, k))),
        // console.log(_.get(data ?? {}, k), _.get(initialValues ?? {}, k), hasDataChangedWrapper(_.get(data ?? {}, k), _.get(initialValues ?? {}, k)));
        [data, initialValues, formData, hasDataChangedWrapper],
    );

    /**
     * Indicator that the form is valid by checking that no field has an error value.
     * @type {boolean} true if the form is valid, false otherwise
     */
    const isValid = useMemo(() => _.every(formData, (d) => _.isUndefined(d.error)), [formData]);

    /**
     * Checks, if an attribute is required
     * @type {(attribute:string)=>boolean}
     */
    const isRequired = useCallback((attribute) => {
        if (formValidationSchema?.frontend?.[formValidationSchemaType]?.save?.[attribute]?.includes('required')) { return formValidationSchema?.attributes?.[attribute]?.required; }
        return false;
    }, [formValidationSchema?.attributes, formValidationSchema?.frontend, formValidationSchemaType]);

    /**
     * A handler function for retrieving the value from the corresponding form context (View, Edit, List)
     * @type {import('./form').TFormContext['get']}
     */
    const get = useCallback(
        (key) => {
            const value = key !== '*' ? _.get(data, key) : data;
            const formValues = _.get(formData, key) ?? {};
            return {
                displayValue: undefined,
                error: undefined,
                ...formValues,
                value,
            };
        },
        [data, formData],
    );

    /**
     * The validation functions for the form data.
     * This is also the place where additional validations can be added.
     */
    const {
        validateSingle, validateMultiple, checkValidationResults,
    } = useMemo(() => Validator(), []);

    /**
     * Handles changes to the form (user input) and passes it to the form context
     * @type {import('./form').TFormContext['changeHandler']}
     */
    const changeHandler = useCallback(async ({
        attribute,
        value,
        displayValue,
        error,
        ...rest
    }) => {
        let newError = error;
        // updates the value in the itemdata context
        updateValue(attribute, value);

        // if there is an error, validate the field again, so that the error message can be updated.
        const typeSchema = formValidationSchemaFrontend?.[formValidationSchemaType]?.[error?.type];
        if (error?.type && typeSchema?.[attribute]) {
            const validationResult = await validateSingle(
                typeSchema[attribute],
                formValidationSchemaAttributes[attribute],
                _.isUndefined(value)
                    ? _.get(data, attribute)
                    : value,
                attribute,
                (attributeName) => _.get(data, attributeName),
            );

            // validation successful, remove errer
            if (_.every(validationResult)) {
                newError = undefined;
            }
        }

        setFormData((oldFormData) => {
            const oldData = oldFormData[attribute];
            const isEqualToOldData = _.isEqual(oldData, {error: newError, displayValue, ...rest});
            if (isEqualToOldData) {
                return oldFormData;
            }

            return {
                ...oldFormData,
                [attribute]: {
                    ...oldData,
                    displayValue: _.isUndefined(displayValue)
                        ? oldData?.displayValue
                        : displayValue,
                    error: newError,
                    ...rest,
                },
            };
        });

        onChangeCallback?.(attribute, value, changeHandler, formData);
    }, [updateValue, formValidationSchemaFrontend, formValidationSchemaType, onChangeCallback, formData, validateSingle, formValidationSchemaAttributes, data]);

    const isReadonly = (readonlyRoot ?? false) || (('grants' in data) && !data.grants?.updatable);

    useEffect(() => {
        if (!noChangeTrack && !isReadonly && !isLoading) {
            let globalFormChangeState = _.clone(getGlobal('formHasUnsavedChanges')) ?? {};
            const alreadyIncluded = _.has(globalFormChangeState, formWrapperId);
            if (formHasUnsavedChanges) {
                if (!alreadyIncluded) {
                    globalFormChangeState = {...globalFormChangeState, [formWrapperId]: formHasUnsavedChanges};
                    setGlobal('formHasUnsavedChanges', globalFormChangeState);
                }
            } else if (alreadyIncluded) {
                globalFormChangeState = _.omit(globalFormChangeState, formWrapperId);
                if (_.isEmpty(globalFormChangeState)) {
                    deleteGlobal('formHasUnsavedChanges');
                } else {
                    setGlobal('formHasUnsavedChanges', globalFormChangeState);
                }
            }
        }
    }, [noChangeTrack, isReadonly, formHasUnsavedChanges, formWrapperId, formData, getGlobal, setGlobal, deleteGlobal, isLoading]);

    /**
     * Validate the value of a single field when the user leaves the field,
     * set the error message according to whether or not there was an incorrect value.
     * @type {import('./form').TFormContext['onBlurHandler']}
     */
    const onBlurHandler = useCallback(async ({
        attribute, value, displayValue, ...rest
    }) => {
        // console.log(attribute, value);
        // if no formValidationSchemaFrontend is provided, we can't validate.
        if (!formValidationSchemaFrontend) {
            return changeHandler({
                ...rest,
                attribute,
                value,
                displayValue,
            });
        }
        // get the typeSchema for the current field.
        const {[formValidationSchemaType]: {blur: typeSchema}} = formValidationSchemaFrontend;

        _.forEach(formValidationSchemaFrontend[formValidationSchemaType].blur, (config, attrName) => {
            if (config.includes('dependencyRequired')) {
                // console.log(attrName, config);
                setFormData((current) => _.mapValues(current, (c, a) => {
                    if (a === attrName) {
                        return {
                            ...c,
                            error: undefined,
                        };
                    }

                    return c;
                }));
            }
        });

        // just return if there is no schema for this type
        if (_.isUndefined(typeSchema) || _.isUndefined(typeSchema[attribute])) {
            changeHandler({
                ...rest,
                attribute,
                value,
                displayValue,
                error: undefined,
            });
            return undefined;
        }

        // validate the user input and return the validation object
        const validationResult = await validateSingle(
            typeSchema[attribute],
            formValidationSchemaAttributes[attribute],
            value,
            attribute,
            (attributeName) => _.get(data, attributeName),
        );

        // if the field is required but the value is undefined, ignore the other validations and set an errorValue.
        if (validationResult?.required === false || validationResult?.dependencyRequired === false) {
            return changeHandler({
                attribute,
                value,
                displayValue,
                ...rest,
                error: {type: 'blur', value: 'Dieses Feld ist erforderlich!'},
            });
        }

        if (validationResult?.unique === false) {
            return changeHandler({
                attribute,
                value,
                displayValue,
                ...rest,
                error: {type: 'blur', value: 'Der Wert ist nicht eindeutig!'},
            });
        }

        // if the field is not required, there is any other validation error and the value is not undefined, set the errorValue.
        if (!_.every(validationResult) && !_.isNil(value)) {
            return changeHandler({
                attribute,
                value,
                displayValue,
                ...rest,
                error: {type: 'blur', value: 'Der Wert ist ungültig!'},
            });
        }

        // if everything is good, just return undefined.
        return changeHandler({
            attribute,
            value,
            displayValue,
            error: undefined,
            ...rest,
        });
    }, [formValidationSchemaFrontend, formValidationSchemaType, validateSingle, formValidationSchemaAttributes, changeHandler, data]);

    const {enqueueMessage} = useMessage();
    /**
     * Handles the submit/save of the entire form.
     * Validates the all of form data at once and saves it if it is valid.
     * If it is not valid, it will set the error messages for the fields.
     * @type {import('./form').TFormContext['saveHandler']}
     */
    const saveHandler = useCallback(async (variables) => {
        const draft = variables.draft ?? false;
        const saveNow = async () => {
            if (isNewItem) {
                disableReloadOnce.current = true;
            }
            const savePromise = save({messageKey, onSaveCallback, variables});
            return savePromise.then((success) => {
                if (success) {
                    // removes the changed flag from all fields
                    setFormData((oldFormData) => _.mapValues(oldFormData, (value) => ({...value, changed: undefined})));
                    // change direct to avoid showing the unsaved changes modal when working really fast (like cypress)
                    const globalFormChangeState = _.omit(getGlobal('formHasUnsavedChanges'), formWrapperId);
                    if (_.isEmpty(globalFormChangeState)) {
                        deleteGlobal('formHasUnsavedChanges');
                    } else {
                        setGlobal('formHasUnsavedChanges', globalFormChangeState);
                    }
                }
                return success;
            });
        };
            // if we are missing values, just save as we probably don't need to validate
        if (!formValidationSchemaFrontend) {
            return saveNow();
        }
        const typeSchema = formValidationSchemaFrontend?.[validatorSchema?.type]?.save;
        // just return if there is no schema for this type
        if (_.isUndefined(typeSchema)) {
            return saveNow();
        }

        // call validateMultiple with the values we got from the FormWrapper and tell the function how to the the input value
        const validationResults = await validateMultiple(typeSchema, formValidationSchemaAttributes, (key) => get(key).value);

        // if there was an error on a field, set the error message.
        _.forEach(validationResults, ({
            required, dependencyRequired, unique, ...validationResultsRest
        }, attributeName) => {
            const {value} = get(attributeName);
            if (draft && _.isEmpty(value)) {
                // clearing out error values that are not relevant for drafts
                changeHandler({attribute: attributeName});
                return;
            }
            // If there was an validation object returned, if they is, it will check if every value in the object is true.
            // If there is no validation object, it will return true (as there is no error).
            // If there is a single false, it will return false (as there is an error).
            // const restResult = validationResultsRest ? _.every(validationResultsRest) : true;
            // get the current value of the field
            const currValue = get(attributeName);

            // if the field is not required, the validation returned true and the field has a value.
            // if ((required === false || dependencyRequired === false) || (!restResult && !_.isEmpty(value))) {
            //     // set the current value with the new error message
            //     changeHandler({
            //         attribute: attributeName,
            //         value: currValue.value,
            //         displayValue: currValue.displayValue,
            //         error: {type: 'save', value: 'Der Wert ist ungültig!'},
            //     });
            // }
            // if the field is required but the value is undefined, ignore the other validations and set an errorValue.
            if (required === false || dependencyRequired === false) {
                changeHandler({
                    attribute: attributeName,
                    value: currValue.value,
                    displayValue: currValue.displayValue,
                    error: {type: 'blur', value: 'Dieses Feld ist erforderlich!'},
                });
                return;
            }

            if (unique === false) {
                changeHandler({
                    attribute: attributeName,
                    value: currValue.value,
                    displayValue: currValue.displayValue,
                    error: {type: 'blur', value: 'Der Wert ist nicht eindeutig!'},
                });
                return;
            }

            // if the field is not required, there is any other validation error and the value is not undefined, set the errorValue.
            if (!_.every(validationResultsRest) && !_.isNil(value)) {
                changeHandler({
                    attribute: attributeName,
                    value: currValue.value,
                    displayValue: currValue.displayValue,
                    error: {type: 'blur', value: 'Der Wert ist ungültig!'},
                });
            }
        });

        // check if all validations are valid
        if (draft) {
            const values = Object.keys(_.pickBy(validationResults, (value, key) => !_.isEmpty(get(key).value)));
            if (values.length === 0) {
                enqueueMessage(messageKey, Exceptions.EMPTY_ITEM);
            } else if (draft && isDraft(validationResults, values)) {
                saveNow();
            } else {
                enqueueMessage(messageKey, Exceptions.INVALID_DRAFT);
            }

        // transform the results object into a single end result (boolean)
        } else if (checkValidationResults(validationResults, (key) => get(key)?.value)) {
            return saveNow();
        }
        return false;
    }, [formValidationSchemaFrontend, validatorSchema?.type,
        validateMultiple, formValidationSchemaAttributes,
        checkValidationResults, isNewItem, save, messageKey,
        onSaveCallback, getGlobal, formWrapperId, deleteGlobal, setGlobal, get, changeHandler, enqueueMessage]);

    /**
     * @type {import('./form').TFormContext['reload']}
     */
    const reload = useCallback(() => {
        // reset the errors and make the form elements not interacted
        // tha values stay
        setFormData((current) => _.mapValues(current, (c) => ({..._.omit(c, 'error'), interacted: false})));

        if (!disableReloadOnce.current) {
            return load({messageKey});
        }
        // to avoid a reload from create to update this is set once!
        // will be set in the save function
        // the messageKey changes in create to update
        disableReloadOnce.current = false;
        return null;
    }, [load, messageKey]);

    /**
     * The effect will load the initial data into the form state,
     * initialize the change processes and handle changes in parameter.
     */
    useEffect(() => {
        // if (initialData) {
        const addedData = _.omitBy(data, (v, k) => _.isEqual(_.get(initialData, k), v));
        if (_.isEmpty(addedData) && !isLoading) {
            // Trying to load data into the parent ItemData context. May result in no-op.
            reload();
        }
        // }
    }, [reload, data, initialData, isLoading]);

    // when the context changes, the form should reload
    // usefull for taking the id of the entity as context - when changed, the form reloads
    useEffect(() => {
        if (context && context !== currentContext) {
            reload();
            setCurrentContext(context);
        }
    }, [context, currentContext, reload, setCurrentContext]);

    useEffect(() => {
        if (sync) {
            const sync$ = sync({messageKey});
            const subscription = sync$.subscribe();
            return () => subscription.unsubscribe();
        }
        return () => {};
    }, [sync, messageKey]);

    const {getVariables} = useVariables();
    const {getItem} = mockables.AWSAppSyncProvider();

    const [isLoadingRecommendation, setIsLoadingRecommendation] = useState(false);
    const getRecommendationItem = useCallback((query, variables) => {
        setIsLoadingRecommendation(true);
        return getItem(query, variables).finally((result) => {
            setIsLoadingRecommendation(false);
            return result;
        });
    }, [getItem, setIsLoadingRecommendation]);

    const recommendationManager = useMemo(
        () => (recommendationConfig
            ? new RecommendationManager(getRecommendationItem, recommendationConfig)
            : undefined),
        [recommendationConfig, getRecommendationItem],
    );

    useEffect(() => {
        if (recommendationManager && !recommendationManager.disabled) {
            recommendationManager.loadRecommendations(
                {
                    changeHandler,
                    getVariables,
                    enqueueMessage: (message) => enqueueMessage(messageKey, message),
                },
                _.keys(recommendationConfig.valueDependencies),
                _.keys(recommendationConfig.optionDependencies),
            );

            // @ts-ignore .flush not found because of method reassignment
            recommendationManager.loadRecommendations.flush();
            return () => {
                recommendationManager.disabled = true;
            };
        }
        return _.noop;
    }, [changeHandler, enqueueMessage, getVariables, messageKey, recommendationConfig?.optionDependencies, recommendationConfig?.valueDependencies, recommendationManager]);

    useEffect(() => {
        if (recommendationManager) {
            const renewedData = _.chain(data)
                .keys()
                .concat(_.keys(formData))
                .uniq()
                .map((attribute) => [attribute, {
                    value: data[attribute],
                    ...formData[attribute],
                }])
                .fromPairs()
                .value();
            recommendationManager.currentData = renewedData;
            recommendationManager.checkChangesAndUpdate({
                getVariables,
                enqueueMessage: (message) => enqueueMessage(messageKey, message),
                changeHandler,
            });
        }
    }, [recommendationManager, data, formData, changeHandler, enqueueMessage, getVariables, messageKey]);

    const clearRef = useRef(() => {
        setFormData((current) => (_.isEmpty(current) ? current : Object()));
    });

    const providerValue = useMemo(() => ({
        id: formWrapperId,
        reload,
        isValid,
        isNewItem,
        formHasChanges: formHasUnsavedChanges,
        isReadonly,
        isLoading: {
            load: isLoading,
            save: isSaving,
            recommendation: isLoadingRecommendation,
        },
        get,
        clear: clearRef.current,
        formValidationSchemaAttributes,
        saveHandler,
        changeHandler,
        onBlurHandler,
        formAttributeIsRequired: isRequired,
    }), [formWrapperId, isValid, isNewItem, formHasUnsavedChanges, isReadonly, isLoading, isSaving, isLoadingRecommendation, formValidationSchemaAttributes,
        get, reload, saveHandler, changeHandler, onBlurHandler, isRequired]);

    return (
        <FormContext.Provider value={providerValue}>
            <form onSubmit={preventDefault} style={{display: 'contents'}} noValidate>
                {children}
            </form>
        </FormContext.Provider>
    );
}

export {FormWrapper, FormContext, mockables};
