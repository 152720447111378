/**
 * Fragment that loads entiites that are relevant for displaying names and images
 * of entities in the permission entity chooser
 */
export const PermissionEntitiesFragment = /* GraphQL */`
  fragment PermissionEntities on Links {
    users {
      id
      active
      contactFirstName
      contactLastName
      contactEMailAddress
      grants {
        permissionsUpdatable
      }
      image { 
        url
        options{
          image{
            position{
                x
                y
                width
                height
            }
          }
        }
       }
    }
    groups {
      id
      name
      grants {
        permissionsUpdatable
      }
    }
    organizationalUnits {
      id
      name
      grants {
        permissionsUpdatable
      }
      image { 
        url
        options{
          image{
            position{
                x
                y
                width
                height
            }
          }
        }
       }
    }
    tenants {
      id
      companyName
      contactFirstName
      contactLastName
      contactEMailAddress
    }
    customers {
      id
      companyName
      titlePrefixed
      firstName
      lastName
      titlePostfixed
      eMailAddress
    }
    graves {
      id
      generalNr
      division
      subDivision
      nr
      choosable
    }
  }
`;

/**
 * Fragment that loads information that are relevant for displaying files
 */
export const FileInformationFragment = /* GraphQL */`
  fragment FileInformation on FileInformation {
    key
    filename
    url
    uploadUrl
    description
    options{
      image{
        position{
            x
            y
            width
            height
        }
      }
    }
  }
`;

/**
 * Fragment that loads information that are relevant for displaying files
 */
export const FileInformationShortFragment = /* GraphQL */`
  fragment FileInformationShort on FileInformation {
    key
    filename
    url
    description
  }
`;

export const WorkingTimeModelHoursFragment = /* GraphQL */ `
  fragment WorkingTimeModelHours on WorkingTimeModelTimeTable {
    monday { from until multiplier}
    tuesday { from until multiplier }
    wednesday { from until multiplier }
    thursday { from until multiplier }
    friday { from until multiplier}
    saturday { from until multiplier }
    sunday { from until multiplier }
  }
`;
