import {
    Skeleton, Checkbox, FormControlLabel, FormHelperText, FormControl,
} from '@mui/material';
import {
    useCallback, useContext, useMemo,
} from 'react';
import {FormContext} from 'components/Form/FormWrapper';
import _ from 'lodash';

/** @type {import('./formElements').FormElementCheckbox} */
function FormElementCheckbox({
    attribute, label, disabled = false, ...rest
}) {
    const {
        get, onBlurHandler, isLoading, isReadonly, formAttributeIsRequired,
    } = useContext(FormContext);

    const isRequired = useMemo(() => formAttributeIsRequired(attribute), [attribute, formAttributeIsRequired]);

    const elementData = useMemo(() => get(attribute), [attribute, get]);
    const onChange = useCallback(() => {
        // changeHandler({
        //     attribute,
        //     value: !elementData.value,
        //     interacted: true,
        // });
        onBlurHandler({
            attribute,
            value: !elementData.value,
            interacted: true,
        });
    }, [onBlurHandler, elementData.value, attribute]);

    /**
     * Displays a loading animation if the context is still loading.
     */
    if (isLoading?.load || isLoading?.save) {
        return <Skeleton variant="rectangular" animation="wave" height="3rem" />;
    }

    return (
        <FormControl error={!_.isUndefined(elementData?.error)}>
            <FormControlLabel
                label={label}
                aria-readonly={isReadonly}
                required={isRequired}
                control={(
                    <Checkbox
                        data-test={`FormElementCheckbox_${attribute}`}
                        checked={elementData.value ?? false} // false is default value
                        onChange={onChange}
                        disabled={disabled || isReadonly}
                        readOnly={isReadonly}
                        {...rest}
                    />
                )}
            />
            <FormHelperText>{elementData?.error?.value}</FormHelperText>
        </FormControl>
    );
}

export {FormElementCheckbox};
