import {useCallback, useContext, useMemo} from 'react';
import {FormContext} from 'components/Form/FormWrapper';
import {
    IconButton, CircularProgress, Collapse, Box,
    Badge,
    Button,
} from '@mui/material';
import {SaveAs, Save as SaveIcon} from '@mui/icons-material';

/**
 * A FormElementLoadingButton component that is used to save a form and shows a loading animation.
 * @param {object} props - props for the FormElement
 * @param {"icon"|"text"|"contained"} [props.variant] - the variant of the button.
 * @param {string} [props.label] - the label of the button.
 * @param {import('react').ReactElement} [props.startIcon] - the icon that is shown before the label.
 * @param {boolean} [props.disabled] - if true, the button cannot be clicked.
 * @param {object} [props.onClick] - the onClick function of the button that can be used instead of the form submit.
 * @param {object} [props.children] - children of the component
 * @param {boolean} [props.draft] - flag set the save behavior for the button to draft mode
 * @param {Record<string, any>} [props.saveVariables] - additional variables to be passed to the save handler
 * @param {import('react').CSSProperties} [props.style] - styling of the button
 * @param {'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'} [props.color] - color of the button
 * @example <FormElementLoadingButton variant="icon" label="Save" />
 * @returns {import('react').ReactElement} The FormElementLoadingButton Component
 */
function FormElementLoadingButton({
    variant, label, startIcon, disabled, onClick, children, draft, saveVariables, style, color = 'primary', ...rest
}) {
    /**
     * Destructuring the FormContext and assigning the values.
     */
    const {
        isLoading, saveHandler, isValid, isReadonly,
    } = useContext(FormContext);

    const onClickButton = useMemo(
        () => onClick ?? (() => saveHandler({
            draft,
            ...saveVariables,
        })),
        [saveHandler, draft, onClick, saveVariables],
    );

    const render = useCallback(
        /**
         * @param {import('react').ReactNode} child
         * @returns {import('react').ReactElement}
         */
        (child) => (
            <Collapse in={!isReadonly}>
                {(isValid || draft) && child}
                {(!isValid && !draft) && <Badge badgeContent="Formular unvollständig" color="secondary">{child}</Badge>}
            </Collapse>
        ),
        [draft, isReadonly, isValid],
    );

    const buttonDisabled = isLoading?.save || !(draft || isValid) || (disabled ?? false) || isReadonly;// || (!formHasChanges && !isNewItem);

    // /**
    //  * Displays a loading animation if the context is still loading.
    //  */
    // if (isLoading?.load || isLoading?.save) {
    //     return (
    //         <Skeleton
    //             variant="rectangular"
    //             animation="wave"
    //             width="100%"
    //             height="49.44px"
    //             data-test="FormElementLoadingButton_skeleton"
    //         />
    //     );
    // }

    switch (variant) {
    case 'icon':
        return render(
            <IconButton
                style={{width: '3rem', height: '3rem', ...style}}
                // Type switches to allow for other buttons that are not disabled to trigger
                type={(!buttonDisabled && 'submit') || undefined}
                data-test={`FormElementLoadingButton_${label ?? 'Speichern'}`}
                onClick={onClickButton}
                disabled={buttonDisabled}
                color={color}
                {...rest}
            >
                {isLoading?.save ? (
                    <Box
                        display="grid"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress size="1rem" color="inherit" />
                    </Box>
                ) : children ?? <SaveIcon />}
            </IconButton>,
        );
    default:
        return render(
            <Button
                style={style}
                // Type switches to allow for other buttons that are not disabled to trigger
                type={(!buttonDisabled && 'submit') || undefined}
                data-test={`FormElementLoadingButton_${label ?? 'Speichern'}`}
                onClick={onClickButton}
                variant={variant ?? 'contained'}
                // @ts-ignore - Not an error.
                startIcon={startIcon ?? (label === 'Entwurf' ? <SaveAs /> : <SaveIcon />)}
                loading={isLoading?.save}
                disabled={buttonDisabled || isLoading?.load}
                color={color}
                {...rest}
            >
                {label ?? 'Speichern'}
            </Button>,
        );
    }
}

export {FormElementLoadingButton};
