import {
    useContext, useMemo, useState,
} from 'react';
import {Box, CircularProgress} from '@mui/material';
import classNames from 'classnames';
import {useGlobalState} from 'hooks/useGlobalState';
import {useMessage} from 'hooks/useMessage';
import {Warnings} from 'messages/Warnings';
import {FormContext} from 'components/Form/FormWrapper';
import {S3UploadProvider} from 'helper/bb-s3upload-provider';
import {uploadWrapper} from 'components/Form/FormElements/FormElementFileUploadWrapper';

import classes from 'components/Form/FormElements/formElementFileDropzone.module.scss';

/**
 * A FormElement that shows an FormElementFileDropzone.
 * @param {object} props - props that are passed to the component
 * @param {string} [props.attribute] - attribute key of the form source. The default value for the attribute is "imageKey".
 * @param {string} [props.fileReference] - fileReference key of the form source
 * @param {boolean} [props.multiple] - are multiple files allowed
 * @param {boolean} [props.alwaysHot] - always show the drop zone
 * @param {boolean} [props.disabled] - disables the dropZone
 * @param {import('helper/helper').S3UploadFunction} [props.upload] - S3 upload function
 * @returns {import('react').ReactElement} The FormElementFileDropzone Component
 */
function FormElementFileDropzone({
    attribute, fileReference, multiple, alwaysHot, disabled, upload,
}) {
    const [dragging, setDragging] = useState(false);
    const {getGlobal} = useGlobalState();
    const {enqueueMessage} = useMessage();
    const globalDragging = getGlobal('dragging');

    /**
     * Destructuring the FormContext and assigning the values.
     */
    const {
        get, formValidationSchemaAttributes, changeHandler, isReadonly, isLoading,
    } = useContext(FormContext);

    const formats = useMemo(() => formValidationSchemaAttributes?.[attribute]?.fileExtensions, [formValidationSchemaAttributes, attribute]);

    /**
     * Retrieving the value of the Reference from the context
     * In case of no value, it returns the corresponding value for "no value" (e.g. null)
     */
    const referenceData = useMemo(
        () => get(fileReference),
        [fileReference, get],
    );

    const innerUpload = S3UploadProvider();
    const actualUpload = upload ?? innerUpload.upload;
    const isDisabled = disabled || innerUpload.isUploading;

    const uploadOptions = useMemo(() => ({
        upload: (files) => actualUpload(files, {formats}), multiple, changeHandler, attribute, fileReference, referenceData,
    }), [formats, actualUpload, multiple, changeHandler, attribute, fileReference, referenceData]);

    const handleDragOver = isReadonly ? undefined : (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(true);
    };

    const handleDrop = isReadonly ? undefined : (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);

        const {files} = e.dataTransfer;

        if (!multiple && files?.length > 1) {
            enqueueMessage('FileDropZone', Warnings.FILEUPLOAD_TOO_MANY_FILES);
            return;
        }
        if (files && files?.length) {
            uploadWrapper(files, uploadOptions);
        }
    };

    const handleDragLeave = isReadonly ? undefined : (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
    };

    return (
        <Box className={classes.container}>
            <div
                className={classNames(classes.dropZone, {
                    [classes.dragging]: (!isDisabled && globalDragging) || alwaysHot,
                    [classes.hot]: !isDisabled && dragging,
                })}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onDragLeave={handleDragLeave}
                data-test="FormElementFileDropZone"
            />
            <Box style={{display: (isLoading.load || isLoading.save) ? 'flex' : 'none'}} className={classNames(classes.container, {[classes.loading]: (isLoading.load || isLoading.save)})}>
                <CircularProgress />
            </Box>
        </Box>
    );
}

export {FormElementFileDropzone};
